@import "../../basedata/variables"

@media screen and (max-width: 768px)

  .instructions-panel-Bottom
    width: 100vw
    display: flex
    flex-direction: column
    align-items: center
    max-height: calc(100vh - 100px)
    justify-content: flex-start
    background-color: $gray-100
    position: fixed
    bottom: 0
    border-top-left-radius: 16px
    border-top-right-radius: 16px
    transition: transform 0.3s ease-in-out
    overflow-y: auto
    overflow-x: hidden
    touch-action: pan-y
    z-index: 4

    &.open
      transform: translateY(0)
      height: 100vh

    &.closed
      transform: translateY(calc(100% - 70px))

    .lingueta
      width: 120px
      height: 8px
      background-color: $gray-500
      border-radius: 3px
      align-items: center
      justify-content: center
      cursor: pointer
      margin: 15px

    .lingueta:active
      cursor: grabbing

    .instructions-content
      overflow: auto

@media screen and (max-width: 401px)
  .instructions-panel
    width: 100vw
    max-height: calc(100vh - 50px)
    border-top-left-radius: 12px
    border-top-right-radius: 12px

    .lingueta
      width: 120px
      height: 8px
      margin: 15px

    &.closed
      transform: translateY(calc(100% - 70px))

    &.open
      transform: translateY(0)
      height: 100vh