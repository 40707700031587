@import "./../../basedata/variables"

@media screen and (max-width: 768px)
  .closed
    .instructions-content
      overflow: hidden
  .instructions-content
    text-align: center

    .instruction-title
      font-size: 30px
      font-weight: 700
      margin: 0
      line-height: 40px
      color: $primary-500
      text-align: left

      &.text-center
        text-align: center

    p
      font-size: 18px
      font-weight: 400
      margin: 0 0 30px 0
      line-height: 30px
      color: $gray-800 

  .img-dica img
    width: 270px
    height: 161px
    margin-bottom: 24px
    filter: hue-rotate(278deg) saturate(0.4)

  ul
    padding: 0
    .instruction-item
      display: flex
      align-items: flex-start
      margin: 10px 20px
      gap: 10px

      .number-instruction
        width: 40px
        height: 40px
        display: flex
        justify-content: center
        align-items: center
        font-size: 20px
        font-weight: 700
        color: $white
        background: $primary-500
        border-radius: 50%

      .instruction-header
        display: flex
        align-items: center
        gap: 10px

      .instruction-text
        width: 332px
        margin: 0 0 30px 0
        text-align: left
        display: flex
        align-items: flex-start
        flex-direction: column


        .instruction-title
          font-size: 24px
          font-weight: 700
          margin: 0
          line-height: 40px
          color: $primary-500

        p
          font-size: 0.64em
          line-height: 0.8em
          font-weight: 400
          line-height: 30px
          color: $gray-800

  .button-container
    margin-top: 24px
    text-align: center
    margin-bottom: 30px

    .retry-button
      width: 388px
      height: 60px
      background: $primary-500
      color: $white
      font-size: 20px
      font-weight: 500
      padding: 12px 24px
      border: none
      border-radius: 100px
      line-height: 30px
      cursor: pointer
      text-align: center

      &:hover
        background: $primary-600

  .footer
    grid-column: 1 / -1
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    bottom: 40px
    margin-bottom: 20px

    .footer-logo
      width: 120px

@media screen and (max-width: 401px)
  .instructions-content
    text-align: center

    .instruction-title
      font-size: 20px
      line-height: 32px

    .img-dica img
      width: 220px
      height: auto
      margin-bottom: 16px
      filter: hue-rotate(278deg) saturate(0.4)

    ul
      .instruction-item
        margin: 30px
        gap: 8px

        .number-instruction
          width: 30px
          height: 30px
          font-size: 14px

        .instruction-text
          width: 300px
          height: auto

          .instruction-title
            font-size: 0.8em
            line-height: 1em

          p
            font-size: 0.62em
            line-height: 1em

  .button-container
    margin-top: 16px
    margin-bottom: 20px

    .retry-button
      width: 300px
      height: 50px
      font-size: 16px
      padding: 10px 20px
      border-radius: 50px

  .footer
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    padding: 12px 0
    position: relative
    bottom: 0
    margin-bottom: 20px

    .footer-logo
      width: 100px