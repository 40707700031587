@import "../basedata/variables"

@media screen and (max-width: 768px)

  .container-selfie-capture
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    box-sizing: border-box
    overflow: hidden

  .container-selfie-capture-inside,
  .container-auto-capture
    width: 100%
    height: 100%

  .clip-selfie
    width: 100%
    height: 100%

    position: absolute
    left: 0
    top: 0
    z-index: 2


    background-color: rgb(0 0 0 / 50%)


    -webkit-mask: url('../images/selfie_frame.svg') 0/100% 100%, linear-gradient(#fff,#fff)
    -webkit-mask-composite:destination-out
      mask: url('../images/selfie_frame.svg') 0/100% 100%,linear-gradient(#fff,#fff)
    mask-composite: exclude

  .frame-selfie
    width: 200px
    height: 200px

    position: absolute
    left: 26%
    top: 36%
    z-index: 3

    img
      width: 100%

    &.centered img
      filter: invert(1)

  .blur-background
    filter: blur(10px)

  .selfie-taken
    height: 100vh
    width: 100vw
    object-fit: cover
    transform: scaleX(-1)
