@import "./../../basedata/variables"

.container-selfie-invalid
  width: 100vw
  height: 100vh
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  box-sizing: border-box
  
  .instruction-title,
    .instruction-paragraph
    display: none

  .instruction-title-invalid
      font-size: 30px
      font-weight: 700
      margin: 0
      line-height: 40px
      color: $primary-500
      text-align: center

  .logo
    width: 100px
    height: auto
    margin-bottom: 20px

  .selfie-invalid-content

    h1, p
      text-align: center
      margin-bottom: 20px

  .instruction-title
      text-align: center
      margin-bottom: 20px
    
