@import "./../../basedata/variables"

.container-selfie-invalid
  width: 100vw
  height: 100vh
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  box-sizing: border-box

  .logo
    width: 100px
    height: auto
    margin-bottom: 20px

  .selfie-invalid-content

    h1, p
      text-align: center
      margin-bottom: 20px

  .instruction-title
      text-align: center
      margin-bottom: 20px
    
