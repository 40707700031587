@import "../basedata/variables"


.document-type-select
  list-style: none
  
  li
    display: flex
    border: 2px solid $primary
    border-radius: 20px
    text-align: left
    padding: 16px
    align-items: center
    margin-bottom: 16px

    .type-select-description
      margin-left: 16px

    .bepass-title
      font-size: 20px
      margin-bottom: 0

    i
      img
        max-width: 60px
        width: 100%