@import "./../../basedata/variables"

.container-selfie-success
  display: flex
  
  h1
    text-align: center
    font-size: 28px
    margin: 0

  p:nth-of-type(2)
    font-size: 14px
    line-height: 20px

  button.btn_send_document
    display: flex
    align-items: center
    justify-content: center
    gap: 8px
    background-color: $primary-500
    color: $white
    font-weight: bold
    padding: 10px 16px
    border: none
    border-radius: 50px
    cursor: pointer
    transition: all 0.3s ease
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
    margin-top: 32px

    img
      width: 40px
      height: 40px

    span
      font-size: 14px
      font-weight: 500

    &:hover
      background-color: $primary-600
      transform: translateY(-2px)
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15)

    &:active
      transform: translateY(0)
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
