@import "../basedata/variables"

.success-container
  display: flex
  flex-direction: column
  min-height: 100vh
  
  .progress-indicator
    display: none  

  .right-side
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .success-card
      background-color: white
      border-radius: 50px
      text-align: center
      width: 559px
      height: 750px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      padding: 40px 
      box-sizing: border-box

      .logo
        width: auto
        max-height: 85px
        margin-bottom: 36px 

      .success_title
        @include title
        color: $primary-500
        margin-bottom: 16px 

      .success-message-container
        .success-message
          @include subtitle
          margin-bottom: 16px 
        
        .success-image
          margin: 10px auto
          max-width: 250px

          img
            width: 100%
        
  .success-footer
    grid-column: 1 / -1 
    width: 100%
    display: flex
    justify-content: center
    align-items: center

    .success-footer-logo
      width: 160px   
      height: 36px   
      background-size: contain  
      background-repeat: no-repeat 
      background-position: center 
      background-image: url(#{$bepass-white-logo})

/* Estilos para mobile */
@media (max-width: 768px)
  .success-container
    display: flex
    flex-direction: column
    height: 100vh
    width: 100%  
    background-image: none 

    .progress-indicator
      margin: 32px auto
      display: flex
      gap: 4px

      .progress-bar
        width: 50px
        height: 4px
        border-radius: 4px
        background-color: $primary-500
    
    .left-side
      display: none  

    .right-side
      max-width: 100% 
      display: flex
      justify-content: center
      align-items: center

      .success-card
        max-width: 100%  
        width: 90vw 
        height: 100%  
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 20px  
        margin-top: 40px
        border-radius: 0 

    .success-footer
      position: relative
      height: 60px  
      display: flex
      justify-content: center
      align-items: center
    
      .success-footer-logo
        width: 160px  
        height: 36px  
        background-image: url(#{$bepass-color-logo})  
        background-size: contain
        background-repeat: no-repeat
        background-position: center

.button-container-wrap
  margin-top: 24px
  text-align: center
  margin-bottom: 30px

  .retry-button
    width: 388px
    height: 60px
    background: $primary-500
    color: $white
    font-size: 20px
    font-weight: 500
    padding: 12px 24px
    border: none
    border-radius: 100px
    line-height: 30px
    cursor: pointer
    text-align: center

    &:hover
      background: $primary-600