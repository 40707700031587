@import "../../basedata/variables"

.loader
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 100vh

  .spinner
    width: 50px
    height: 50px
    border: 5px solid $gray-300
    border-top: 5px solid $primary-500
    border-radius: 50%
    animation: spin 1s linear infinite

  p
    @include bodyTextSmall
    margin-top: 10px

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
        
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 100vh

.spinner
  width: 50px
  height: 50px
  border: 5px solid $gray-300
  border-top: 5px solid $primary-500
  border-radius: 50%
  animation: spin 1s linear infinite

  p
    @include bodyTextSmall
    margin-top: 10px

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
