@import '../../basedata/variables'

.modal-overlay
  position: fixed
  z-index: 20
  border-radius: 20px
  background-color: $white
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
  text-align: center
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 90%
  max-width: 500px

.modal-content
  margin: 20px
  h1
    text-align: center
    font-size: 30px
    font-weight: 700
    line-height: 45px
    color: $primary-500

  p
    font-size: 20px
    font-weight: 400
    line-height: 30px
    text-align: left
    padding: 0 10px 0 10px
    text-underline-position: from-font
    text-decoration-skip-ink: none


  button
    width: 100%
    background: $primary-500
    color: $white
    font-size: 20px
    font-weight: 500
    padding: 12px 24px
    border: none
    border-radius: 100px
    line-height: 30px
    cursor: pointer
    text-align: center
    margin-bottom: 20px