@import "../basedata/variables"

body
  @include title
  
.color-table
  display: flex
  justify-content: space-between
  margin-top: 20px

.column
  flex: 1
  margin: 0 10px

.color-box
  height: 50px 
  margin: 5px 0 
  display: flex
  align-items: center
  justify-content: center
  color: #000 
  border-radius: 5px 

.primary-100
  background-color: $primary-100

.primary-200
  background-color: $primary-200

.primary-300
  background-color: $primary-300

.primary-400
  background-color: $primary-400

.primary-500
  background-color: $primary-500

.primary-600
  background-color: $primary-600

.primary-700
  background-color: $primary-700

.primary-800
  background-color: $primary-800

.primary-900
  background-color: $primary-900

.secondary-100
  background-color: $secondary-100

.secondary-200
  background-color: $secondary-200

.secondary-300
  background-color: $secondary-300

.secondary-400
  background-color: $secondary-400

.secondary-500
  background-color: $secondary-500

.secondary-600
  background-color: $secondary-600

.secondary-700
  background-color: $secondary-700

.secondary-800
  background-color: $secondary-800

.secondary-900
  background-color: $secondary-900

.gray-100
  background-color: $gray-100

.gray-200
  background-color: $gray-200

.gray-300
  background-color: $gray-300

.gray-400
  background-color: $gray-400

.gray-500
  background-color: $gray-500

.gray-600
  background-color: $gray-600

.gray-700
  background-color: $gray-700

.gray-800
  background-color: $gray-800

.gray-900
  background-color: $gray-900

.button-group
  margin-top: 20px