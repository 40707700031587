@import "./../basedata/variables"

.qr-code
  max-width: 260px
  width: 70%
  margin: 32px auto
  img
    width: 100%

.card-instructions
  width: 100%
  overflow-wrap: break-word
