@import "./../../basedata/variables"

.loader-container
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 350px
  height: 24px
  background-color: $primary-200
  border-radius: 100px
  overflow: hidden
  opacity: 1
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
  z-index: 10

  .loader-bar
    height: 100%
    background-color: $primary-500
    border-radius: 100px
    transition: width 0.1s linear

  .loader-percentage
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-family: Arial, sans-serif
    font-size: 14px
    font-weight: bold
    color: $white

.container-selfie-done
  height: 100%
  width: 100%

  .selfie-confirmation-content
    display: flex
    flex-direction: column

    p
      font-size: 18px
      font-weight: 400
      margin: 0
      line-height: 30px

    .selfie-confirmation-text
      margin: 0 20px
      h1
        font-size: 30px
        font-weight: 700
        margin-bottom: 10px
        margin-top: 0
        line-height: 40px
        color: $primary-500
        text-align: center

      p
        color: $gray-800

    .try-again-section
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      margin: 20px 20px 30px 20px

      .try-again-link
        display: flex
        align-items: center
        font-size: 18px

        p, svg
          color: $primary

      button
        width: 100%
        background: $primary-500
        color: $white
        font-size: 20px
        font-weight: 500
        padding: 12px 24px
        border: none
        border-radius: 100px
        line-height: 30px
        cursor: pointer
        text-align: center
        margin-bottom: 20px
