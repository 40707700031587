@import "../../basedata/variables"

.mobile-pagination

    .progress-indicator
        display: flex
        align-items: center
        justify-content: center
        margin: 20px
        gap: 4px

        .progress-bar
            width: 50px
            height: 4px
            border-radius: 4px

        .set-green
            background-color: $primary-500

        .set-gray
            background-color: $gray
