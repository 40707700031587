@import "./basedata/variables"

.main-wrapper
  display: flex
  justify-content: flex-end
  align-items: center
  width: 100%
  min-height: 100vh
  background-image: url(./src/#{$background-welcome})
  background-size: cover
  background-position: center
  padding: 0 10%

@media screen and (max-width: 768px)
  .main-wrapper
    padding: 0
    background: #fff !important

  .footer
    margin-top: 32px

.onboarding-content-wrapper
  background-color: $white
  border-radius: 50px
  max-width: 560px
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  padding: 40px
  padding-top: 0

  .logo
    width: auto
    max-height: 85px
    margin-bottom: 36px 

@media screen and (max-width: 768px)
  .onboarding-content-wrapper
    max-width: 100%
.bepass-title
  +title
  color: $primary

.bepass-text-default
  +defaultText
  text-align: left
  color: $gray-800

.bepass-text-label
  +defaultLabel
  text-align: left
  color: $gray-800

// Form styles
.terms-container
  @include bodyTextSmall
  color: $black
  display: flex
  align-items: normal
  margin-bottom: 32px

  input[type="checkbox"] 
    width: 44px
    height: 22px
    margin: 4px 16px 0 0
    appearance: none
    border-radius: 4px
    background-color: $gray-500
    cursor: pointer
    transition: background-color 0.3s ease

  input[type="checkbox"]:checked 
    background-color: $primary-500

  input[type="checkbox"]:checked::after 
    content: "✓"
    font-size: 14px
    color: white
    display: flex
    justify-content: center
    align-items: center

  .accept-terms
    .bold-link
      @include bodyTextSmallBold
      color: $black

// Buttons
.continue-button
  @include buttonLarge
  background-color: $primary-500
  color: $white
  border: none
  border-radius: 100px
  cursor: pointer
  transition: background-color 0.3s
  max-height: 60px
  width: 100%
  padding: 16px 24px

  &:disabled
    background-color: $gray-500
    cursor: not-allowed

// Inputs
.bepass-form
  .MuiFormControl-root
    width: 100%
    margin-bottom: 16px

    .MuiFormLabel-root
      +defaultLabel
      color: $black

    .MuiInputBase-root
      +defaultLabel
      padding: 8px 16px
      border: 1px solid $gray-300
      border-radius: 12px
      color: $black
      margin-top: 32px
      text-align: left

      &::before,
      &::after
        display: none

      .country-flag
        display: inline-block
        height: 24px
        width: 24px
        overflow: hidden
        border-radius: 100%
        margin-right: 8px
        vertical-align: middle
      
        span
          font-size: 50px
          display: block
          margin-left: -16px
          margin-top: -2px

  .label-error
    +defaultLabel
    color: $erro


// well :( 
img.img-dica,
.success-image img
  filter: hue-rotate(278deg) saturate(0.4)

.no-svg-tip
  width: 100%
  filter: hue-rotate(212deg)

.button-retry-action
  margin-top: 32px

.select-country-flag
  width: 20px
  height: 20px
  margin-right: 8px
  vertical-align: middle

#mui-component-select-nationality
  .select-country-flag
    width: 40px
    height: 40px
    margin-top: -8px
    margin-left: -8px


// card top
.logo
  margin-top: 32px