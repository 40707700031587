@import "../../basedata/variables"

body, html
    width: 100%
    height: 100%
    margin: 0
    overflow-x: hidden 
    overflow-y: auto 
    touch-action: pan-y 

@media screen and (max-width: 768px)
    .poc
        width: auto
        margin: 100px

    .mobile-capture-container
        width: 100vw
        height: 100vh
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        box-sizing: border-box
        overflow-x: hidden


        .capture-button
            width: 218px
            height: 60px
            display: flex
            align-items: center
            justify-content: center
            background-color: $primary-500
            color: $white
            font-size: 20px
            font-weight: 500
            line-height: 30px
            text-align: center
            border: none
            border-radius: 50px
            cursor: pointer
            gap: 8px

            &:hover
                background-color: $primary-600

        .camera-icon
            width: 40px
            height: 30px

    .instructions-panel
        width: 100vw
        height: 100vh 
        max-height: calc(100vh - 70px)
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        background-color: $gray-100
        position: absolute
        bottom: 0
        border-top-left-radius: 16px
        border-top-right-radius: 16px
        transition: transform 0.3s ease-in-out
        touch-action: none
        overflow-y: auto
        overflow-x: hidden 
        touch-action: pan-y

        &.open
            transform: translateY(0) 
            height: 100vh 

        &.closed
            transform: translateY(calc(100% - 60px))

        .lingueta
            width: 150px
            height: 10px
            background-color: $gray-500
            border-radius: 3px
            align-items: center
            justify-content: center
            cursor: pointer
            margin: 30px

        .lingueta:active
            cursor: grabbing

        .instructions-content
            text-align: center
            overflow-y: auto

            .instruction-title
                font-size: 30px
                font-weight: 700
                margin: 0
                line-height: 40px
                color: $primary-500

        .img-dica img
            width: 270px
            height: 161px
            margin-bottom: 24px
            filter: hue-rotate(278deg) saturate(0.4)

        ul
            list-style: none
            padding: 0
            margin: 0

            .instruction-item
                display: flex
                align-items: flex-start
                margin: 50px
                gap: 10px

                .number-instruction
                    width: 40px
                    height: 40px
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: 20px
                    font-weight: 700
                    color: $white
                    background: $primary-500
                    border-radius: 50%


                .instruction-text
                    width: 332px
                    height: 150px
                    gap: 10px
                    text-align: left


                    .instruction-title
                        font-size: 24px
                        font-weight: 700
                        margin: 0
                        line-height: 40px
                        color: $primary-500

                    p
                        font-size: 18px
                        font-weight: 400
                        margin: 0
                        line-height: 30px
                        color: $gray-800

    

    


        .button-container
            margin-top: 24px
            text-align: center
            margin-bottom: 30px

            .retry-button
                width: 388px
                height: 60px
                background: $primary-500
                color: $white
                font-size: 20px
                font-weight: 500
                padding: 12px 24px
                border: none
                border-radius: 100px
                line-height: 30px
                cursor: pointer
                text-align: center

                &:hover
                    background: $primary-600

    .footer
        grid-column: 1 / -1 
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        bottom: 40px

        .footer-logo
            width: 120px

@media screen and (max-width: 401px)
    .mobile-capture-container
        width: 100vw
        height: 100vh
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        box-sizing: border-box
        overflow-x: hidden

        .capture-button
            width: 180px 
            height: 50px
            font-size: 16px 
            border-radius: 30px
            gap: 6px

            .camera-icon
                width: 30px
                height: 25px

    .instructions-panel
        width: 100vw
        max-height: calc(100vh - 50px) 
        border-top-left-radius: 12px
        border-top-right-radius: 12px

        .lingueta
            width: 120px 
            height: 8px
            margin: 20px

        .instructions-content
            padding: 16px 
            text-align: center

            .instruction-title
                font-size: 20px 
                line-height: 32px

            .img-dica img
                width: 220px 
                height: auto
                margin-bottom: 16px
                filter: hue-rotate(278deg) saturate(0.4)

            ul
                .instruction-item
                    margin: 30px 
                    gap: 8px
                    

                    .number-instruction
                        width: 30px 
                        height: 30px
                        font-size: 14px

                    .instruction-text
                        width: 300px
                        height: auto
                        
                        .instruction-title
                            font-size: 18px 
                            line-height: 28px

                        p
                            font-size: 14px 
                            line-height: 22px

        .button-container
            margin-top: 16px
            margin-bottom: 20px

            .retry-button
                width: 300px 
                height: 50px
                font-size: 16px
                padding: 10px 20px
                border-radius: 50px

    .footer
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        padding: 12px 0
        position: relative 
        bottom: 0

        .footer-logo
            width: 100px 

.inside-modal
  margin-top: 32px