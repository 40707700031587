@import "../../basedata/variables"

.camera-capture-container
  position: relative
  width: 100%
  height: 100vh
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  background-color: $primary-300

  .clip-document
    width: 100%
    height: 100%

    position: absolute
    left: 0
    top: 0
    z-index: 2


    background-color: rgb(0 0 0 / 50%)


    -webkit-mask: url('../../images/document_frame.svg') 0/100% 100%, linear-gradient(#fff,#fff)
    -webkit-mask-composite:destination-out
      mask: url('../../images/document_frame.svg') 0/100% 100%,linear-gradient(#fff,#fff)
    mask-composite: exclude


.video-container
  position: relative
  width: 90%
  height: 75%
  margin-top: 5%
  overflow: hidden
  border-radius: 30px 

.video-preview
  width: 100%
  height: 100%
  object-fit: cover

.frame
  position: absolute
  width: 30px
  height: 30px
  border: 3px solid $primary-500
  z-index: 1

.frame-top-left
  top: 15% 
  left: 15%
  border-right: none
  border-bottom: none

.frame-top-right
  top: 15%
  right: 15%
  border-left: none
  border-bottom: none

.frame-bottom-left
  bottom: 25%
  left: 15%
  border-right: none
  border-top: none

.frame-bottom-right
  bottom: 25%
  right: 15%
  border-left: none
  border-top: none

.controls
  margin-top: 16px
  display: flex
  justify-content: center

.capture-button
  @include buttonLarge
  background-color: $primary-500
  color: $white
  border: none
  border-radius: 4px
  padding: 10px 20px
  cursor: pointer
  transition: background-color 0.3s
  margin-top: 10px
  position: fixed
  bottom: 12%
  z-index: 4

  &:hover
    background-color: $primary-400

.captured-preview
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: rgba(0, 0, 0, 0.8) 
  z-index: 10

.captured-image-container
  top: 0
  left: 0
  width: 100%
  height: calc(100vh - 190px)
  display: flex
  justify-content: center
  align-items: center


.captured-image
  width: 100%
  height: 100% 
  object-fit: cover
  border: none

.loader-container
  position: absolute
  top: 50%
  left: 50% 
  transform: translate(-50%, -50%)
  width: 350px
  height: 24px
  background-color: $white 
  border-radius: 100px
  overflow: hidden
  opacity: 1
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)

.loader-bar 
  height: 100%
  background-color: $primary-500
  border-radius: 100px
  transition: width 0.1s linear

.loader-percentage 
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  font-family: Arial, sans-serif
  font-size: 14px
  font-weight: bold
  color: $white