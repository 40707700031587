@import "../../basedata/variables"

.bepass-text-default
  margin-bottom: 32px

.continue-button
  margin-top: 16px

    
.back-button
  +defaultLabel
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  cursor: pointer
  color: $primary-500
  line-height: 16px
  margin-top: 32px