@import "../../basedata/variables"

.container-auto-capture
  position: relative

  .video
    position: relative
    width: 100vw
    height: 100vh
    object-fit: cover
    transform: scaleX(-1)

  .custom-svg
    position: absolute
    top: 40%
    left: 45%
    transform: translate(-50%, -50%)

  .centered
    stroke: $primary

  .not-centered
    display: none
    stroke: red


